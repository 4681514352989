/* -------------------------------------------------------------------------------
    Variables
   ------------------------------------------------------------------------------- */
/* -------------------------------------------------------------------------------
    HTML Elements
   ------------------------------------------------------------------------------- */
html {
  font-size: 14px;
}
body {
  font-family: 'Helvetica', 'Open San';
  font-weight: 400;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  position: relative;
  min-height: 100.1%;
  font-size: 1.142rem;
  padding-top: 230px;
}
h1,
.h1,
h2,
.h2,
h3,
.h3 {
  text-transform: uppercase;
  font-weight: 700;
  color: #3a5c2b;
}
h2 {
  font-size: 2.142rem;
  margin-bottom: 1rem;
}
h3 {
  font-size: 1.857rem;
}
a {
  color: #76aa6b;
}
a:hover {
  color: $dark-green;
}
:focus,
:visited,
:hover,
:active,
a:focus,
a:visited,
a:hover,
a:active,
button,
button:focus,
button:active,
button:visited {
  outline: 0;
  text-decoration: none;
}
/* -------------------------------------------------------------------------------
    Custom Helpers
   ------------------------------------------------------------------------------- */
.social-icon {
  margin-left: 10px;
}
.social-icon:hover {
  opacity: 0.7;
}
.social-icons {
  position: absolute;
  right: 4%;
  top: 10%;
}
.bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.shadow {
  box-shadow: 2px 2px 8px 0px rgba(0,0,0,0.1);
}
.bg-primary {
  background-color: #8b9a7c !important;
}
.bg-red {
  background-color: #67344b !important;
}
.bg-olive {
  background-color: #b2a79a !important;
}
.overlay {
  display: none;
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.8);
}
.image-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0,0,0,0.5);
}
.image-frame {
  background-color: #fff;
  border: 1px solid rgba(0,0,0,0.125);
  border-radius: 0.25rem;
  padding: 5px;
}
.center-absolute-x {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.center-absolute-y {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-absolute-xy {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}
.center-block-x {
  margin-left: auto !important;
  margin-right: auto !important;
}
.center-flex-y {
/* add class to parent of centred content */
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.center-flex-xy {
/* add class to parent of centred content */
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn {
  transition: all 0.3s;
  border-radius: 0;
}
.btn.btn-primary {
  background-color: #526f44;
  border-color: #526f44;
}
.btn.btn-secondary {
  background-color: #b2a79a;
  border-color: #b2a79a;
}
.btn:hover {
  opacity: 0.5;
}
a.next,
.page-link {
  color: #526f44;
}
.page-item {
  border: 1px solid #526f44;
}
.hide {
  display: none;
}
/* -------------------------------------------------------------------------------
    Components
   ------------------------------------------------------------------------------- */
.page {
  min-height: 100vh;
}
.component {
  position: relative;
}
.component,
.spaced {
  padding-top: 3em;
  padding-bottom: 3em;
}
.component-carousel,
.component-header,
.component-slider {
  padding-top: 0;
  paddign-bottom: 0;
  z-index: 11;
}
.component-header .header-text {
  position: absolute;
  z-index: 2;
  top: 50%;
  transform: translateY(-50%);
}
.component-slider .slick-slide {
  height: inherit !important;
}
.component-quote-content {
  padding-top: 40vh;
  margin-top: -33vh;
}
.component-quote-content .component-inner {
  display: flex;
  align-items: flex-end;
}
.component-quote-content .quote {
  width: 351px;
  padding: 30px 34px 30px 0;
  color: #fff;
  font-size: 14px;
  font-family: 'Cormorant Infant', serif;
}
.component-quote-content .quote span {
  display: block;
  margin-top: 30px;
}
.component-quote-content .content {
  padding: 40px 50px;
  background-color: #fff;
  color: #000;
  font-weight: 300;
}
.component-quote-content .content em {
  font-weight: 600;
  font-style: italic;
}
.component-content-blocks .content .content-row,
.component-profiles .content .content-row,
.component-pages-nav .content .content-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}
.component-content-blocks .content .wrapper,
.component-profiles .content .wrapper,
.component-pages-nav .content .wrapper {
  width: 30%;
  margin: 25px 18px;
}
.component-content-blocks .content .wrapper img,
.component-profiles .content .wrapper img,
.component-pages-nav .content .wrapper img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.component-content-blocks .content .wrapper p,
.component-profiles .content .wrapper p,
.component-pages-nav .content .wrapper p {
  margin-bottom: 0;
}
.component-content-blocks .content .wrapper .copy,
.component-profiles .content .wrapper .copy,
.component-pages-nav .content .wrapper .copy {
  min-height: 249.75px;
  max-height: 249.75px;
  overflow: hidden;
  background-color: #8b9a7c;
  color: #fff;
  position: relative;
  padding: 25px 15px;
}
.component-content-blocks .content .wrapper .copy a,
.component-profiles .content .wrapper .copy a,
.component-pages-nav .content .wrapper .copy a {
  color: #fff;
}
.component-content-blocks .content .wrapper .title,
.component-profiles .content .wrapper .title,
.component-pages-nav .content .wrapper .title {
  text-align: center;
  padding: 15px;
  color: #fff;
  background-color: #526f44;
  font-family: 'Helvetica', 'Open San';
  font-weight: 400;
  font-weight: 700;
}
.component-profiles {
  padding-top: 50vh;
  margin-top: -40vh;
  z-index: 0;
}
.component-profiles .content .wrapper .title {
  background-color: #fff;
  color: #67344b;
}
.component-quote-block {
  margin: 0 auto;
  z-index: 1;
}
.component-quote-block .component-inner {
  padding: 20px 45px 20px 100px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.component-quote-block .content {
  background-color: #fff;
  color: #000;
  width: 50%;
  padding: 30px 30px;
  font-weight: 300;
}
.component-quote-block .content span {
  font-size: 1rem;
  font-family: 'Cormorant Infant', serif;
  font-weight: 400;
}
.component-results,
.component-event-results-search {
  color: #526f44;
}
.component-results select,
.component-event-results-search select {
  height: 50px;
  background-color: #526f44;
  color: #fff;
  border: 1px solid #526f44;
  margin: 20px 30px;
  width: 270px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0;
}
.component-results select::placeholder,
.component-event-results-search select::placeholder {
  color: #fff;
}
.component-results select.custom-select,
.component-event-results-search select.custom-select {
  background-image: url("../img/icon-arrow-down.svg");
  background-repeat: no-repeat;
  background-size: 20px;
  background-position-y: center;
  background-position-x: 242px;
}
.component-results select:focus,
.component-event-results-search select:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #526f44;
}
.component-results .filter,
.component-event-results-search .filter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.component-pages-nav {
  padding-bottom: 20em;
  margin-top: 200px;
}
.component-pages-nav .content {
  background-color: #fff;
  color: #45443f;
  padding: 50px 40px;
}
.component-pages-nav .content .content-row {
  justify-content: center;
  margin: 0 -25px;
}
.component-pages-nav .content .wrapper {
  width: 29%;
}
.component-pages-nav .content .wrapper .copy {
  min-height: 170px;
  max-height: 170px;
}
.component-pages-nav .component-inner {
  background-color: #b2a79a;
  padding: 65px;
  margin-top: -250px;
}
.component-sponsor {
  padding-bottom: 20em;
  margin-top: 200px;
}
.component-sponsor .content {
  background-color: #fff;
  color: #45443f;
  padding: 50px 40px;
  padding-bottom: 120px;
}
.component-sponsor .content .content-row {
  justify-content: center;
}
.component-sponsor .content .wrapper {
  width: 29%;
}
.component-sponsor .content .wrapper .copy {
  min-height: 170px;
  max-height: 170px;
}
.component-sponsor .component-inner {
  background-color: #67344b;
  padding: 65px;
  margin-top: -460px;
}
.component-sponsor .sponsor {
  float: right;
}
.component-content img {
  margin: 10px auto;
}
.component-content figure {
  display: flex;
}
.component-content h2 {
  text-align: center;
}
.component-content .container {
  background-color: #b2a79a;
  padding: 40px;
}
.component-content .container .component-inner {
  background-color: #fff;
  padding: 30px;
}
.event-result p {
  font-size: 20px;
}
.no-results {
  font-size: 20px;
}
/* Carousel & Slider */
.slick-slide {
  padding: 0 1em;
  height: 20vw;
}
.slick-list {
  margin: 0 -1em;
}
.carousel-item,
.component-header {
  height: calc(100vh - 230px);
  overflow: hidden;
}
.component-header {
  margin: 0 auto;
}
.carousel-control-next,
.carousel-control-prev {
  opacity: 0.4;
  transition: 0.3s ease opacity;
  z-index: 2;
}
.carousel-control-next.slick-disabled,
.carousel-control-prev.slick-disabled,
.carousel-control-next.disabled,
.carousel-control-prev.disabled {
  opacity: 0.2 !important;
  cursor: default;
}
.carousel-control-next:focus,
.carousel-control-prev:focus {
  opacity: 0.6;
}
.carousel-control-next:hover,
.carousel-control-prev:hover {
  opacity: 1;
}
.carousel-control-next .carousel-control-next-icon,
.carousel-control-prev .carousel-control-next-icon,
.carousel-control-next .carousel-control-prev-icon,
.carousel-control-prev .carousel-control-prev-icon {
  width: 20px;
  height: 20px;
}
.carousel-indicators li {
  width: 30px;
  height: 3px;
  opacity: 1;
  transition: 0.3s ease all;
  cursor: pointer;
}
.carousel-indicators li.active,
.carousel-indicators li.slick-active {
  background: #fff;
}
.carousel-indicators li:hover {
  background: rgba(255,255,255,0.8);
}
.video-btn {
  position: absolute;
  margin-top: 160px;
  z-index: 10000;
  float: left;
  margin-left: 270px;
  background-color: rgba(255,255,255,0.4);
  color: #fff;
  font-weight: 700;
  font-size: 18px;
  border: 5px solid #fff;
  border-radius: 12px;
  text-transform: uppercase;
  text-shadow: 0 0.25em 0.25em rgba(0,0,0,0.65) !important;
}
.video-btn:hover {
  color: #fff;
  opacity: 0.8;
}
.slider-mode-text .slick-slide {
  padding: 1em 4em;
  text-align: center;
}
.slider-mode-text .carousel-indicators {
  bottom: -40px;
}
.slider-mode-text .carousel-control-prev {
  left: -8%;
}
.slider-mode-text .carousel-control-next {
  right: -8%;
}
@media only screen and (max-width: 768px) {
  .slider-mode-text .carousel-control-prev {
    left: 0%;
  }
  .slider-mode-text .carousel-control-next {
    right: 0%;
  }
}
/* Cards / Portfolio */
.card .card-body .card-thumbnail {
  max-height: 140px;
  overflow: hidden;
}
.card .card-body .card-text {
  height: 100px;
  overflow: hidden;
}
.home #footer {
  margin-top: -280px !important;
}
/* -------------------------------------------------------------------------------
    Style Bootstrap Classes
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 992px) {
  #nav {
    height: 230px;
  }
}
.subnav {
  top: 230px;
  z-index: 1020;
}
.has-subnav {
  margin-top: 230px;
}
.navbar-nav > li:first-child > a.nav-link {
  padding-left: 0px;
}
.navbar {
  backface-visibility: hidden;
}
.navbar .dropdown-menu li {
  width: 100%;
  margin-left: 0 !important;
}
/*  -------------------------------------------------------------------------------
     Navigation
    ------------------------------------------------------------------------------- */
.subnav {
  display: none;
}
#nav {
  transition: all 0.5s;
  background-color: #fff;
  box-shadow: none;
}
#nav .navbar-brand {
  margin-right: 0;
  margin-left: 30px;
}
#nav .logo {
  transition: all 0.5s;
}
#nav .nav-link {
  font-size: 1.142rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #45443f;
}
#nav .menu-item {
  margin: 0 30px;
}
/* -------------------------------------------------------------------------------
    Footer
   ------------------------------------------------------------------------------- */
#footer {
  margin-top: 0;
  padding: 40px 0px;
  min-height: 250px;
  font-size: 90%;
  -webkit-font-smoothing: subpixel-antialiased;
}
#footer li {
  margin-bottom: 5px;
}
#footer .fab {
  color: #666;
  margin-right: 5px;
}
#footer .title {
  background-color: #526f44;
  text-align: center;
  color: #fff;
  min-height: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  text-transform: uppercase;
  margin-bottom: 20px;
}
#footer .title strong {
  display: block;
  width: 100%;
}
#footer .contact,
#footer .name {
  color: #45443f;
  margin-bottom: 20px;
  padding: 0 15px;
}
#footer a {
  color: #45443f;
}
/* -------------------------------------------------------------------------------
    Forms
   ------------------------------------------------------------------------------- */
label {
  font-family: 'Helvetica', 'Open San';
  font-weight: 400;
  font-weight: 700;
}
.custom-control label {
  font-family: 'Helvetica', 'Open San';
  font-weight: 400;
}
.result {
  margin-top: 20px;
  margin-bottom: 40px;
  display: none;
}
.result :first-child {
  margin-top: 0;
  padding-top: 0;
}
.parsley-errors-list {
  margin: 0;
  padding: 0;
  list-style: none;
  color: #f00;
  display: block;
  overflow: hidden;
  font-size: 12px;
}
.parsley-error,
:focus.parsley-error {
  border-color: #f00;
}
.form-control::-webkit-input-placeholder {
  color: #999;
}
.form-control:-moz-placeholder {
  color: #999;
}
.form-control::-moz-placeholder {
  color: #999;
}
.form-control:-ms-input-placeholder {
  color: #999;
}
.form-control.placeholder {
  color: #999;
}
/* -------------------------------------------------------------------------------
    Page Specific
   ------------------------------------------------------------------------------- */
.blog h1,
.single-post h1 {
  color: #526f44;
}
.blog .card-footer,
.single-post .card-footer {
  background-color: #526f44;
  border-radius: 0;
}
.blog .card-footer small,
.single-post .card-footer small,
.blog .card-footer strong,
.single-post .card-footer strong {
  color: #fff;
}
.blog .card,
.single-post .card {
  border: 1px solid #526f44;
  border-radius: 0;
}
.blog .card-header,
.single-post .card-header {
  background-color: #526f44;
  color: #fff;
  font-weight: 700;
}
/* -------------------------------------------------------------------------------
    Media Queries
   ------------------------------------------------------------------------------- */
@media only screen and (min-width: 992px) {
  #nav.scrolled {
    height: 120px;
    transition: all 0.5s;
  }
  #nav.scrolled .logo {
    width: 100px;
  }
  .logo-anim-small {
    animation-name: small;
    animation-duration: 0.5s;
  }
  .logo-anim-big {
    animation-name: big;
    animation-duration: 0.5s;
  }
  .component-content-blocks .content,
  .component-profiles .content {
    margin: 0 -25px;
  }
  .component-quote-block,
  .component-header {
    max-width: calc(960px - 30px);
  }
@-moz-keyframes small {
    from {
      width: 150px;
    }
    to {
      width: 100px;
    }
}
@-webkit-keyframes small {
    from {
      width: 150px;
    }
    to {
      width: 100px;
    }
}
@-o-keyframes small {
    from {
      width: 150px;
    }
    to {
      width: 100px;
    }
}
@keyframes small {
    from {
      width: 150px;
    }
    to {
      width: 100px;
    }
}
@-moz-keyframes big {
    from {
      width: 150px;
    }
    to {
      width: 150px;
    }
}
@-webkit-keyframes big {
    from {
      width: 150px;
    }
    to {
      width: 150px;
    }
}
@-o-keyframes big {
    from {
      width: 150px;
    }
    to {
      width: 150px;
    }
}
@keyframes big {
    from {
      width: 150px;
    }
    to {
      width: 150px;
    }
}
}
@media only screen and (min-width: 1200px) {
  .component-quote-block,
  .component-header {
    max-width: calc(1140px - 30px);
  }
}
@media only screen and (max-width: 1199px) {
  #nav .menu-item {
    margin: 0 20px;
  }
  .component-content-blocks .content .wrapper,
  .component-pages-nav .content .wrapper,
  .component-profiles .content .wrapper {
    width: 29%;
  }
  .component-content-blocks .content .wrapper .copy,
  .component-pages-nav .content .wrapper .copy,
  .component-profiles .content .wrapper .copy {
    min-height: 200px;
    max-height: 200px;
  }
  .component-pages-nav .content .wrapper {
    width: 28%;
  }
  .component-pages-nav .content .wrapper .copy {
    min-height: 160px;
    max-height: 160px;
  }
}
@media only screen and (max-width: 991px) {
  html {
    font-size: 12px;
  }
  body {
    padding-top: 132px;
  }
  #nav {
    height: 132px;
  }
  #nav .logo {
    width: 90px;
  }
  #nav #navbar {
    background-color: #fff;
    padding: 20px;
  }
  .component-content-blocks .content .content-row,
  .component-pages-nav .content .content-row,
  .component-profiles .content .content-row {
    justify-content: start;
  }
  .component-content-blocks .content .wrapper,
  .component-pages-nav .content .wrapper,
  .component-profiles .content .wrapper {
    width: 40%;
  }
  .component-pages-nav .content .wrapper {
    width: 26%;
  }
  .component-pages-nav .content .wrapper .copy {
    min-height: 100px;
    max-height: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .social-icons {
    display: none;
  }
  .component-content-blocks .content .wrapper,
  .component-pages-nav .content .wrapper,
  .component-profiles .content .wrapper {
    width: 100%;
  }
  .component-quote-block .component-inner {
    padding: 20px 45px 20px 45px;
  }
  .component-quote-block .content {
    width: 100%;
  }
  .component-pages-nav .component-inner {
    padding: 30px;
  }
  .logo {
    width: 90px;
  }
  .sponsor {
    width: 160px;
  }
}
